import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deepLink, DEEPLINK_INDEX } from 'store/slices';

import {
  redeemCouponV2,
  fetchMembershipPlans,
  fetchPlanBenefitUsageHistory,
  fetchCoupons,
} from 'store/actions';

import { benefitConsts } from 'utils/constBenefits';
import { isMobile, trackEvent } from 'utils';
import { adobeAnalyticsTags, backToDestinations, StatusPlan } from 'utils/constGlobal';

import { Button } from 'components-v2/shared/Button';
import Card from 'components-v2/shared/Card';
import Container from 'components-v2/shared/Container';
import PlanTransitionMessage from 'components-v2/shared/PlanTransitionMessage';
import { Title, Text } from 'components-v2/shared/Elements';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal.js';
import CouponBarCode from './CouponBarCode/CouponBarCode';

import './redeem-benefits.css';
import BackTo from 'components-v2/shared/BackTo/BackTo';
import moment from 'moment';

function RedeemBenefits({
  plansWithPets,
  selectedPlanWithPet = {},
  choosePet,
  isLoading,
  customerFullName,
  palsId,
  deepLinkInfo,
  deepLinking,
  goBackToDashboard,
  showTransitionMessage,
  transitionMessage,
  thirdPartyErrorMessage,
  dashboardInfo,
  goToSubmitClaim,
  toggles,
  uiText,
  redeemBenefitsCouponErrorMessage,
}) {
  const dispatch = useDispatch();
  const [lastChosenBenefit, setLastChosenBenefit] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const [susceptibleBenefitsDeeplink, setSusceptibleBenefitsDeeplink] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [thirdPartyCoupons, setThirdPartyCoupons] = useState([]);
  const THIRD_PARTY_CATEGORY_NAME = 'Your Third Party Benefits';
  const WELCOME_GIFT_BENEFIT_NAME = 'Welcome Gift';

  useEffect(() => {
    if (selectedPlanWithPet.plan) {
      const planStatusActive = selectedPlanWithPet.plan.status === StatusPlan.ACTIVE;
      const planStatusExpired = selectedPlanWithPet.plan.status === StatusPlan.EXPIRED;
      if (!planStatusActive && !planStatusExpired) {
        goBackToDashboard();
      }
    }
  }, [selectedPlanWithPet, dispatch, goBackToDashboard]);

  const redeemBenefit = () => {
    dispatch(redeemCouponV2(lastChosenBenefit, (couponsParam) => {
      if (lastChosenBenefit.multipleButtonsWithTargetNames) {
        const chosenTarget = couponsParam.find((param) => param.name === lastChosenBenefit.target);
        setCoupons([chosenTarget]);
      } else if (lastChosenBenefit.categoryName === THIRD_PARTY_CATEGORY_NAME) {
        setThirdPartyCoupons(couponsParam);
      } else {
        setCoupons(couponsParam);
      }
      dispatch(fetchMembershipPlans(true));
      dispatch(fetchPlanBenefitUsageHistory(lastChosenBenefit.planId, true));
    }));
  };

  const handleCouponBenefit = (benefit, planId, target) => {
    if (benefit.allotmentFrequency === benefitConsts.UNLIMITED_ALLOTMENT) {
      dispatch(fetchCoupons(planId, benefit, 'redeemBenefitsCoupons', (freshCoupons) => {
        setCoupons(freshCoupons);
      }));
    }

    window.parent.postMessage({ action: 'scrollTopSmoothly' }, '*');

    setLastChosenBenefit({ ...benefit, planId, target });

    trackEvent({
      formname: 'Membership Dashboard',
      formstepname: 'Redeem benefits',
      formstep: 2,
      subformname: 'View Benefit',
    });
  };

  const handleThirdPartyCouponBenefit = (benefit, planId, target) => {
    const chosenBenefit = { ...benefit, planId, target };
    dispatch(redeemCouponV2(chosenBenefit, (couponsParam) => {
      if (chosenBenefit.categoryName === THIRD_PARTY_CATEGORY_NAME) {
        if (!thirdPartyCoupons.filter(e => e.benefitId === couponsParam[0].benefitId).length > 0) {
          setThirdPartyCoupons((prevState) => [...prevState, couponsParam[0]]);
        }
      }
      dispatch(fetchMembershipPlans(true));
      dispatch(fetchPlanBenefitUsageHistory(chosenBenefit.planId, true));
    }));
  };

  const onClickBenefitHandle = (benefit, planId, target = null) => {
    if (benefit.isCoupon) {
      handleCouponBenefit(benefit, planId, target);
    } else if (benefit.noCouponButtonUrl) {
      window.open(benefit.noCouponButtonUrl, '_blank');
    }
  };

  const onClickThirdPartyBenefitHandle = (benefit, planId, target = null) => {
    if (benefit.isCoupon) {
      trackEvent(adobeAnalyticsTags?.USER_CLICKS_ON_GET_MY_CODE_CTA_ON_THE_ROVER_BENEFIT);
      handleThirdPartyCouponBenefit(benefit, planId, target);
    } else if (benefit.noCouponButtonUrl) {
      window.open(benefit.noCouponButtonUrl, '_blank');
    }
  };

  useEffect(() => {
    if (deepLinking && benefits.length > 0) {
      switch (deepLinkInfo.destination) {
        case DEEPLINK_INDEX.SUBMIT_CLAIM.key: {
          const claimBenefit = susceptibleBenefitsDeeplink
            .find((benefit) => benefit.isClaim);

          if (claimBenefit) {
            goToSubmitClaim(claimBenefit.id, claimBenefit.isRedeemable);
          }
          dispatch(deepLink.actions.resetDeepLink());
          break;
        }
        default:
          dispatch(deepLink.actions.resetDeepLink());
          break;
      }
    }
  }, [dispatch, deepLinkInfo, deepLinking, benefits, susceptibleBenefitsDeeplink, goToSubmitClaim]);

  const orderBenefitsByPosition = (benefits) => {
    const criteria = (benefit1, benefit2) => {
      if (benefit1.position < benefit2.position) {
        return -1;
      }
      if (benefit1.position > benefit2.position) {
        return 1;
      }
      return 0;
    };

    return benefits.sort(criteria);
  };

  const masterPetIdRef = useRef(null);

  useEffect(() => {
    const { membershipBenefits } = selectedPlanWithPet.plan || {};
    const desiredBenefitsToAppearOnRedeemPage = membershipBenefits.filter(
      (benefit) => !benefit.isClaim && benefit.isCoupon,
    );

    if (desiredBenefitsToAppearOnRedeemPage) {
      setBenefits(orderBenefitsByPosition(desiredBenefitsToAppearOnRedeemPage));
      setSusceptibleBenefitsDeeplink(membershipBenefits);
    }
  }, [selectedPlanWithPet]);

  useEffect(() => {
    if (masterPetIdRef.current === selectedPlanWithPet.petMasterId) {
      return;
    }
    const { membershipBenefits } = selectedPlanWithPet.plan || {};
    const thirdPartyBenefit = membershipBenefits.filter(
      (benefit) => benefit.categoryName === THIRD_PARTY_CATEGORY_NAME && moment().toDate().getTime() <= moment(benefit.expirationDate).toDate().getTime(),
    );
    if (thirdPartyCoupons.filter((e) => e.planId !== selectedPlanWithPet.plan.id).length === 0) {
      for (let i = 0; i < thirdPartyBenefit.length; i++) {
        if (!thirdPartyBenefit[i].isRedeemable && thirdPartyBenefit[i].redeemedCount > 0) {
          dispatch(fetchCoupons(selectedPlanWithPet.plan.id, thirdPartyBenefit[i], null, (freshCoupons) => {
            if (!thirdPartyCoupons.filter(e => e.benefitId === freshCoupons[0].benefitId && e.planId !== freshCoupons[0].planId).length > 0) {
              setThirdPartyCoupons((prevState) => [...prevState, freshCoupons[0]]);
            }
          }));
        }
      }
      masterPetIdRef.current = selectedPlanWithPet.petMasterId;
    } else {
      setThirdPartyCoupons([]);
    }
    if (thirdPartyErrorMessage) {
      setThirdPartyCoupons([]);
    }
  }, [dispatch, selectedPlanWithPet, thirdPartyCoupons, thirdPartyErrorMessage]);

  const showBarCode = lastChosenBenefit !== null;

  const getClassName = (benefit) => {
    if (benefit.isRedeemable) {
      return 'redeemable benefit-title';
    } else if (lastChosenBenefit) {
      return 'middle benefit-title';
    } else if (benefit.categoryName === THIRD_PARTY_CATEGORY_NAME) {
      return 'redeemable benefit-title';
    } else {
      return 'exhausted benefit-title';
    }
  };

  const oneTimeUseCoupon = (benefit) => benefit.allotmentFrequency === 'fixed'
    && benefit.allotment === 1;

  const getUsedTargetName = (benefit) => {
    const usage = dashboardInfo?.planBenefitUsageHistory?.benefitUsages?.find((usage) => benefit.id === usage.benefitId);
    return Array.isArray(usage?.redemptionInfo) ? usage?.redemptionInfo[0].name : usage?.redemptionInfo?.name;
  };

  const checkIfWelcomeBenefitAvailable = () => {
    const welcomeBenefitObj = benefits.filter((benefit) => benefit.name === WELCOME_GIFT_BENEFIT_NAME);
    if (welcomeBenefitObj.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const roverErrorString = (str) => {
    if (str) {
      const linkSplit = str.split('<br/>');
      return <span key={linkSplit}>{linkSplit[0]} <br /> {linkSplit[1]}</span>;
    }
  };

  useEffect(() => {
    adobeAnalyticsTags.USER_LANDS_ON_REDEEM_BENEFITS_PAGE.page.buildVersion = toggles?.FRONTEND_VERSION;
    trackEvent(adobeAnalyticsTags?.USER_LANDS_ON_REDEEM_BENEFITS_PAGE);
  }, []);

  const showThirdPartyCoupon = (benefitId) => {
    const thirdPartyCouponObjBasedOnID = thirdPartyCoupons.filter((thirdParty) => thirdParty.benefitId === benefitId);
    if (thirdPartyCouponObjBasedOnID.length > 0 && thirdPartyCouponObjBasedOnID[0].couponNumber) {
      return true;
    } else {
      return false;
    }
  };

  return (!deepLinking && (
    <div data-testid="RedeemBenefitsPage" className="RedeemBenefitsPage-v2">

      {!isMobile && (
        <Container>
          <BackTo where={backToDestinations.DASHBOARD} desktop />
        </Container>
      )}

      <DashboardHeader
        selectedPlanWithPet={selectedPlanWithPet}
        plansWithPets={plansWithPets}
        choosePet={choosePet}
        toggles={toggles}
        uiText={uiText}
        screenName="hide"
      />

      {isMobile && (
        <Container>
          <BackTo where={backToDestinations.DASHBOARD} />
        </Container>
      )}

      {showTransitionMessage && (
        <PlanTransitionMessage
          transitionMessage={transitionMessage}
        />
      )}

      <Container className="containerBody">
        {/* All Benefit Cards apart from ThirdParty */}
        <Title as="h5" className="bold-font">
          Show these barcodes to a Petco Store Partner to redeem benefits for {selectedPlanWithPet.name}
        </Title>
        <div className="RedeemBenefitsPage__CardsWrapper-v2">
          {benefits.length > 0 && benefits.filter((benefit) => benefit.categoryName !== THIRD_PARTY_CATEGORY_NAME && benefit.name !== WELCOME_GIFT_BENEFIT_NAME).map((benefit) => {
            const benefitOneTimeUse = () => {
              if (benefit.isRedeemable && oneTimeUseCoupon(benefit)) {
                return (
                  <>
                    {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <p className="one-time-use one-time-use-UM">ONE TIME USE</p> : <p className="one-time-use">ONE TIME USE</p>}
                  </>
                );
              } else if (!benefit.isRedeemable && lastChosenBenefit && oneTimeUseCoupon(benefit)) {
                return (
                  <>
                    {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <p className="one-time-use one-time-use-UM">ONE TIME USE</p> : <p className="one-time-use">ONE TIME USE</p>}
                  </>
                );
              }
            };

            /* Splitting the strings for the aria-label - screen reader voice. */
            const vetExamString = benefit.buttonCopy;
            const [vetExamWord1, vetExamWord2] = vetExamString.split(' a ');
            const benefitString = benefit.buttonCopy;
            const [benefitWord1, benefitWord2] = benefitString.split(' ');

            return (
              <Card className={`${benefit.id === lastChosenBenefit?.id && redeemBenefitsCouponErrorMessage !== undefined && redeemBenefitsCouponErrorMessage.message ? 'no-height-auto' : 'Card-Height no-height-auto'}`} key={benefit.id}>
                <div className="RedeemBenefitsPage__Header-v2">
                  {benefitOneTimeUse()}
                  {oneTimeUseCoupon(benefit)
                    && !benefit.isRedeemable
                    && !lastChosenBenefit
                    && (
                      <p className="exhausted-badge">
                        USED {benefit.redeemedOn}
                      </p>
                    )}
                  <h4
                    className={getClassName(benefit)}
                    data-testid={`card-group-${benefit.categoryId}`}
                  >
                    {selectedPlanWithPet.name}'s {benefit.name}
                  </h4>
                  <Text className="above-button-description">{benefit.aboveButtonDescription}</Text>
                </div>

                <div className="RedeemBenefitsPage__BenefitWrapper">
                  <div className="RedeemBenefitsPage__ShowBarcodeButtonWrapper">
                    {showBarCode && benefit.id === lastChosenBenefit?.id && coupons.length !== 0 && redeemBenefitsCouponErrorMessage === undefined ? (
                      <>
                        {benefit.targets?.length > 1 && (
                          <p className="exhausted-intermediate-state">{getUsedTargetName(benefit)}</p>
                        )}
                        <CouponBarCode
                          coupons={coupons}
                        />
                        {benefit.belowButtonUrl ? (
                          <div className="RedeemBenefitsPage__Link">
                            <a href={benefit.belowButtonUrl} target="_blank" rel="noopener noreferrer">
                              {benefit.belowButtonDescription}
                            </a>
                          </div>
                        ) : (
                          benefit.belowButtonDescription && (
                            <div className="RedeemBenefitsPage__Link">
                              <span>{benefit.belowButtonDescription}</span>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {benefit.isRedeemable ? (
                          <>
                            {!benefit.multipleButtonsWithTargetNames && (
                              <Button
                                className={
                                  `SubmitButton--Desktop ${benefit.isRedeemable ? '' : 'SubmitButton--Disabled'}`
                                }
                                label={benefit.buttonCopy}
                                ariaLabel={`${benefit.name === 'Vet Exam' ? `${vetExamWord1} ${selectedPlanWithPet.name}'s ${vetExamWord2}` : `${benefitWord1} ${selectedPlanWithPet.name}'s ${benefit.name} ${benefitWord2}`}`}
                                disabled={!benefit.isRedeemable}
                                onClick={() => onClickBenefitHandle(benefit, selectedPlanWithPet.plan.id)}
                              />
                            )}
                            {benefit.id === lastChosenBenefit?.id && redeemBenefitsCouponErrorMessage !== undefined && redeemBenefitsCouponErrorMessage.message && (
                              <h6 className="error redeem-benifits-coupon-error">
                                {redeemBenefitsCouponErrorMessage.message}
                              </h6>
                            )}
                            {benefit.multipleButtonsWithTargetNames && benefit.targets.map((target) => (
                              <Button
                                key={target.name}
                                id="multiple-target-button"
                                className={
                                  `SubmitButton--Desktop ${benefit.isRedeemable ? '' : 'SubmitButton--Disabled'}`
                                }
                                label={target.name}
                                disabled={!benefit.isRedeemable}
                                onClick={() => onClickBenefitHandle(benefit, selectedPlanWithPet.plan.id, target.name)}
                              />
                            ))}
                            {benefit.belowButtonUrl ? (
                              <div className="RedeemBenefitsPage__Link">
                                <a href={benefit.belowButtonUrl} target="_blank" rel="noopener noreferrer">
                                  {benefit.belowButtonDescription}
                                </a>
                              </div>
                            ) : (
                              benefit.belowButtonDescription && (
                                <div className="RedeemBenefitsPage__Link">
                                  <span>{benefit.belowButtonDescription}</span>
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <p className="exhausted">{getUsedTargetName(benefit)}</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Card>
            );
          })}
        </div>

        <div className={checkIfWelcomeBenefitAvailable() ? 'WelcomeGiftAndRoverInRow' : ''}>
          <div className="RedeemBenefitsPage__CardsWrapper-v2">
            {benefits.length > 0 && benefits.filter((benefit) => benefit.name === WELCOME_GIFT_BENEFIT_NAME).map((benefit) => {
              const benefitOneTimeUse = () => {
                if (benefit.isRedeemable && oneTimeUseCoupon(benefit)) {
                  return (
                    <>
                      {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <p className="one-time-use one-time-use-UM">ONE TIME USE</p> : <p className="one-time-use">ONE TIME USE</p>}
                    </>
                  );
                } else if (!benefit.isRedeemable && lastChosenBenefit && oneTimeUseCoupon(benefit)) {
                  return (
                    <>
                      {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <p className="one-time-use one-time-use-UM">ONE TIME USE</p> : <p className="one-time-use">ONE TIME USE</p>}
                    </>
                  );
                }
              };

              /* Splitting the strings for the aria-label - screen reader voice. */
              const vetExamString = benefit.buttonCopy;
              const [vetExamWord1, vetExamWord2] = vetExamString.split(' a ');
              const benefitString = benefit.buttonCopy;
              const [benefitWord1, benefitWord2] = benefitString.split(' ');

              return (
                <Card className="Card-Height" key={benefit.id}>
                  <div className="RedeemBenefitsPage__Header-v2">
                    {benefitOneTimeUse()}
                    {oneTimeUseCoupon(benefit)
                      && !benefit.isRedeemable
                      && !lastChosenBenefit
                      && (
                        <p className="exhausted-badge">
                          USED {benefit.redeemedOn}
                        </p>
                      )}
                    <h4
                      className={getClassName(benefit)}
                      data-testid={`card-group-${benefit.categoryId}`}
                    >
                      {selectedPlanWithPet.name}'s {benefit.name}
                    </h4>
                    <Text className="above-button-description">{benefit.aboveButtonDescription}</Text>
                  </div>

                  <div className="RedeemBenefitsPage__BenefitWrapper">
                    <div className="RedeemBenefitsPage__ShowBarcodeButtonWrapper">
                      {showBarCode && benefit.id === lastChosenBenefit?.id ? (
                        <>
                          {benefit.targets?.length > 1 && (
                            <p className="exhausted-intermediate-state">{getUsedTargetName(benefit)}</p>
                          )}
                          <CouponBarCode
                            coupons={coupons}
                          />
                          {benefit.belowButtonDescription && (
                            <div className="RedeemBenefitsPage__Link">
                              <span>{benefit.belowButtonDescription}</span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {benefit.isRedeemable ? (
                            <>
                              {!benefit.multipleButtonsWithTargetNames && (
                                <Button
                                  className={
                                    `SubmitButton--Desktop ${benefit.isRedeemable ? '' : 'SubmitButton--Disabled'}`
                                  }
                                  label={benefit.buttonCopy}
                                  ariaLabel={`${benefit.name === 'Vet Exam' ? `${vetExamWord1} ${selectedPlanWithPet.name}'s ${vetExamWord2}` : `${benefitWord1} ${selectedPlanWithPet.name}'s ${benefit.name} ${benefitWord2}`}`}
                                  disabled={!benefit.isRedeemable}
                                  onClick={() => onClickBenefitHandle(benefit, selectedPlanWithPet.plan.id)}
                                />
                              )}
                              {benefit.multipleButtonsWithTargetNames && benefit.targets.map((target) => (
                                <Button
                                  key={target.name}
                                  id="multiple-target-button"
                                  className={
                                    `SubmitButton--Desktop ${benefit.isRedeemable ? '' : 'SubmitButton--Disabled'}`
                                  }
                                  label={target.name}
                                  disabled={!benefit.isRedeemable}
                                  onClick={() => onClickBenefitHandle(benefit, selectedPlanWithPet.plan.id, target.name)}
                                />
                              ))}
                              {benefit.belowButtonUrl ? (
                                <div className="RedeemBenefitsPage__Link">
                                  <a href={benefit.belowButtonUrl} target="_blank" rel="noopener noreferrer">
                                    {benefit.belowButtonDescription}
                                  </a>
                                </div>
                              ) : (
                                benefit.belowButtonDescription && (
                                  <div className="RedeemBenefitsPage__Link">
                                    <span>{benefit.belowButtonDescription}</span>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              <p className="exhausted">{getUsedTargetName(benefit)}</p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>

          {/* ThirdParty Independent Card */}
          <div className={`${toggles?.ENABLE_ROVER20 ? 'thirdParty-wrapper-v2 thirdParty-wrapper' : 'thirdParty-wrapper'}`}>
            {benefits.length > 0 && benefits.filter((benefit) => benefit.categoryName === THIRD_PARTY_CATEGORY_NAME).length > 0
              && (
                <Title as="h5" className={`bold-font ${checkIfWelcomeBenefitAvailable() && 'alignExclusiveMemberTextCenter'}`}>
                  Exclusive Member Offers
                </Title>
              )}
            <div className="RedeemBenefitsPage__CardsWrapper-v2">
              {benefits.length > 0 && benefits.filter((benefit) => benefit.categoryName === THIRD_PARTY_CATEGORY_NAME && moment().toDate().getTime() <= moment(benefit.expirationDate).toDate().getTime()).map((benefit) => (
                <Card key={benefit.id}>
                  <div className="RedeemBenefitsPage__Header-v2">
                    {toggles?.ENABLE_ROVER20 && !showThirdPartyCoupon(benefit.id) && (
                      <div className="newWord">{uiText?.roverNewBadgeCopy}</div>
                    )}
                    {benefit.categoryName === THIRD_PARTY_CATEGORY_NAME
                      && (
                        <>
                          <img tabIndex={0} className="thirdParty_Benefit_Logo" src={benefit.iconUrl} alt="Rover Logo" />
                          {!toggles?.ENABLE_ROVER20 && (
                            <>
                              {toggles?.ENABLE_UNIFIED_MEMBERSHIP ? <p className="one-time-use one-time-use-UM">ONE TIME USE</p> : <p className="one-time-use">ONE TIME USE</p>}
                            </>
                          )}
                        </>
                      )}
                    {benefit.categoryName === THIRD_PARTY_CATEGORY_NAME
                      && oneTimeUseCoupon(benefit)
                      && (
                        <h4
                          className={getClassName(benefit)}
                          data-testid={`card-group-${benefit.categoryId}`}
                        >
                          {benefit.marketingDescription}
                        </h4>
                      )}
                    {toggles?.ENABLE_ROVER20 && (
                      <p className="showExpiryDate">{`${uiText?.roverCodesExpiresTxt} ${moment(benefit.expirationDate).format('MM/DD/YYYY')}`}</p>
                    )}
                    <Text tabIndex={0} className="above-button-description">{benefit.aboveButtonDescription}</Text>
                  </div>

                  <div className="RedeemBenefitsPage__BenefitWrapper">
                    <div className="RedeemBenefitsPage__ShowBarcodeButtonWrapper">
                      {benefit.isRedeemable ? (
                        <>
                          {!benefit.multipleButtonsWithTargetNames && (
                            <Button
                              className={
                                `SubmitButton--Desktop ${benefit.isRedeemable ? '' : 'SubmitButton--Disabled'}`
                              }
                              label={benefit.buttonCopy}
                              disabled={!benefit.isRedeemable}
                              onClick={() => onClickThirdPartyBenefitHandle(benefit, selectedPlanWithPet.plan.id)}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {benefit.categoryName === THIRD_PARTY_CATEGORY_NAME && !benefit.isRedeemable ? (
                            <div className="Redeemed_ThirdPartyCoupon_Button" tabIndex={0}>
                              {/* Below there's a split() added on Aria-label for the screen reader to spell the code */}
                              {thirdPartyCoupons.filter((thirdParty) => thirdParty.benefitId === benefit.id).map((thirdPartyCoupon) => (
                                <span aria-label={thirdPartyCoupon.couponNumber.split('')}>{thirdPartyCoupon.couponNumber}</span>
                              ))}
                            </div>
                          ) : (<p className="exhausted">{getUsedTargetName(benefit)}</p>)}
                        </>
                      )}
                      {benefit.categoryName === THIRD_PARTY_CATEGORY_NAME
                        && !showThirdPartyCoupon(benefit.id)
                        && thirdPartyErrorMessage !== undefined
                        && (
                          <h6 className="error thirdParty-error">
                            {roverErrorString(uiText?.roverErrorMessage)}
                          </h6>
                        )}
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>

        <ConfirmationModal
          show={showBarCode}
          benefit={lastChosenBenefit}
          pet={selectedPlanWithPet.pet}
          isLoading={isLoading}
          customerFullName={customerFullName}
          palsId={palsId}
          onHide={() => setLastChosenBenefit(null)}
          redeemBenefit={redeemBenefit}
        />
      </Container>
    </div>
  ));
}

export default RedeemBenefits;
