/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import React from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  globalConsts, StatusPlan, YOUR_PET_NAME_FALLBACK_LABEL,
} from 'utils/constGlobal';
import { navigationHistory, VIEW_INDEX } from '../store/slices';
import { DateTime } from 'luxon';
import Icon from 'components-v2/shared/Icon/Icon';

export const isMobile = JSON.parse(localStorage.getItem(globalConsts.LOCALSTORE_MOBILE_KEY));

export const getBirthdate = (selectedMonth, year, day) => DateTime.fromObject({ year, month: selectedMonth, day }).toISODate();

export const getBirthdateNew = (selectedMonth, year, selectedDay) => DateTime.fromObject({ year, month: selectedMonth, day: selectedDay }).toISODate();

export const formatDateRange = (startDate, endDate) => {
  const startDateTime = DateTime.fromISO(startDate);
  const endDateTime = DateTime.fromISO(endDate);

  return `${startDateTime.toLocaleString(DateTime.DATE_SHORT)}-${endDateTime.toLocaleString(DateTime.DATE_SHORT)}`;
};

export const formatPrice = (price) => parseFloat(price).toFixed(2);

export const formatPhone = (input) => {
  if (!input) return '';
  if (input.includes('+')) input = input.substring(2);
  if (typeof (input) !== 'string') input = input.toString();
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    return '';
  }
};

export const formatToTitleCase = (str) => {
  if (!str) {
    return '';
  } else {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
  }
};

export const getMoment = (dateStr, format) => moment.parseZone(dateStr, format);

export const getAge = (dateStr) => (dateStr
  ? getMoment(dateStr, 'MM/DD/YYYY').fromNow(true)
  : null);

export const getMonth = (dateStr) => getMoment(dateStr).format('MM');

export const getDay = (dateStr) => getMoment(dateStr).format('DD');

export const getYear = (dateStr) => getMoment(dateStr).format('YYYY');

export const getMonthYear = (dateStr) => getMoment(dateStr).format('MMMM YYYY');

export const getDateMonthDayYearFormat = (dateStr) => moment(dateStr).format('MMM D, YYYY');

export const getDateFullMonthDayYearFormat = (dateStr) => moment(dateStr).format('MMMM D, YYYY');

export const formatDate = (dateStr) => getMoment(dateStr).format('MMMM D');

export const formatDateToCalendar = (dateStr) => getMoment(dateStr).format('MM/DD/YYYY');

export const formatTime = (dateStr) => getMoment(dateStr).format('h:mmA');

export const getDayOfWeek = (dayIndex) => [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'][dayIndex];

export const getStartDateMonth = (dateStr) => getMoment(dateStr).format('MMM');

export const timeAgoInSeconds = (dateStr) => moment().diff(moment(dateStr), 'seconds');

export const getRelativeDate = ({ years = 0, months = 0, days = 0 }) => {
  const date = moment().subtract(years, 'years').subtract(months, 'months').subtract(days, 'days');
  return date.format('MM/DD/YYYY');
};

export const getAgeInString = (datestr) => {
  const diff2 = moment(datestr).diff(moment(), 'milliseconds');
  const duration = moment.duration(diff2);
  let years = duration.years().toString().replace('-', '');
  let months = duration.months().toString().replace('-', '');
  let days = duration.days().toString().replace('-', '');
  years = years > 0 ? `${years} years ` : '';
  months = months > 0 ? `${months} months ` : '';
  days = days > 0 ? `${days} days` : '';
  return `${years}${months}${days}`;
};

export const getPlansWithPetSortedByStatus = (membershipPlans, pets) => {
  const plansWithPet = [];

  membershipPlans.forEach((plan) => {
    const petOnPlan = pets.find((pet) => plan.pet === pet.petMasterId);
    const fullInfo = {
      pet: petOnPlan, // done this way becaue petOnPlan might be undefined if not found
      plan, // todo update to list of plans and search plansWithPet for existing petId and add to list
      petMasterId: plan.pet,
    };

    fullInfo.name = fullInfo.pet && fullInfo.pet.name ? fullInfo.pet.name : YOUR_PET_NAME_FALLBACK_LABEL;
    plansWithPet.push(fullInfo);
  });

  return plansWithPet.sort(
    (planWithPet1, planWithPet2) => {
      const hasPetLoaded = typeof planWithPet1.pet === 'object' && typeof planWithPet2.pet === 'object';

      if (planWithPet1.plan.status === planWithPet2.plan.status) {
        if (!hasPetLoaded || planWithPet1.name === planWithPet2.name) {
          return 0;
        }

        return (planWithPet1.name).toLowerCase() < (planWithPet2.name).toLowerCase() ? -1 : 1;
      }

      if (planWithPet1.plan.status === StatusPlan.ACTIVE) {
        return -1;
      }

      if (planWithPet2.plan.status === StatusPlan.ACTIVE) {
        return 1;
      }

      if (planWithPet1.plan.status === StatusPlan.EXPIRED) {
        return -1;
      }

      return 1;
    },
  );
};

// groups data from benefits & benefit usages
export const getAllUsagesWithBenefitData = (membershipBenefits, benefitUsages) => {
  const usages = [];

  membershipBenefits.forEach((membershipBenefit) => {
    benefitUsages.forEach((benefitUsage) => {
      if (membershipBenefit.id === benefitUsage.benefitId) {
        usages.push({
          benefitUsageId: benefitUsage.id,
          benefitName: membershipBenefit.name,
          benefitUsageUpdateDt: getDateMonthDayYearFormat(moment(benefitUsage.updateDt).format('YYYY-MM-DD')),
          benefitId: benefitUsage.benefitId,
          benefitLongDescription: membershipBenefit.marketingDescription,
          benefitCategoryId: membershipBenefit.categoryId,
          benefitCategoryName: membershipBenefit.categoryName,
          benefitCategoryOrderWeightValue: membershipBenefit.categoryOrderWeightValue,
          benefitValue: membershipBenefit.value,
        });
      }
    });
  });

  return usages;
};

export const orderCategoryUsagesByDate = (categories) => {
  const orderByNewest = (element1, element2) => {
    if (moment(element1.benefitUsageUpdateDt).isBefore(element2.benefitUsageUpdateDt)) {
      return 1;
    }
    if (moment(element2.benefitUsageUpdateDt).isBefore(element1.benefitUsageUpdateDt)) {
      return -1;
    }
    return 0;
  };

  categories.forEach((category) => {
    if (category.usages.length > 0) {
      category.usages.sort(orderByNewest);
    }
  });
};

export const orderByCategoryPriority = (categories) => {
  const orderByLowestToHighest = (category1, category2) => {
    if (category1.priority < category2.priority) {
      return -1;
    }
    if (category1.priority > category2.priority) {
      return 1;
    }
    return 0;
  };

  return categories.sort(orderByLowestToHighest);
};

const getCategoryColor = (categoryName) => {
  let color;

  switch (categoryName) {
    case 'At the Store':
      color = '#06FF86';
      break;
    case 'At the Grooming Salon':
      color = '#9DABC9';
      break;
    case 'At the Vet':
      color = '#001952';
      break;
    case 'Online':
      color = '#E9FDF2';
      break;
    default:
      color = '#06FF86';
  }
  return color;
};

export const groupUsagesByCategory = (categories, usages) => {
  const groupedUsagesByCategory = [];

  const getCategory = (categoryId) => categories.data.find((data) => data.id === categoryId);

  categories.distinctCategoriesIds.forEach((categoryId) => {
    let categoryTotalSpentValue = 0;
    const thisCategoryUsages = [];
    usages.forEach((usage) => {
      if (usage.benefitCategoryId === categoryId) {
        thisCategoryUsages.push(usage);
        categoryTotalSpentValue += usage.benefitValue;
      }
    });

    groupedUsagesByCategory.push({
      id: categoryId,
      name: getCategory(categoryId).name,
      priority: getCategory(categoryId).priority,
      usages: thisCategoryUsages,
      totalSpentValue: categoryTotalSpentValue,
      currentDisplaySize: 5,
      totalUsagesCount: thisCategoryUsages.length,
      color: getCategoryColor(getCategory(categoryId).name),
    });
  });
  return groupedUsagesByCategory;
};

// return unique categories from a plan's membership benefits with some pertinent data
// about the category.
export const getUniqueCategoriesData = (membershipBenefits) => {
  const data = [];
  const distinctCategoriesIds = [];

  membershipBenefits.forEach((membershipBenefit) => {
    if (!distinctCategoriesIds.includes(membershipBenefit.categoryId)) {
      distinctCategoriesIds.push(membershipBenefit.categoryId);
      data.push(
        {
          id: membershipBenefit.categoryId,
          name: membershipBenefit.categoryName,
          priority: membershipBenefit.categoryOrderWeightValue,
        },
      );
    }
  });

  const uniqueCategoriesData = {
    data,
    distinctCategoriesIds,
  };

  return uniqueCategoriesData;
};

export const getPetInfo = (proCareInfo, toggles) => {
  const info = [];
  if (proCareInfo) {
    toggles?.ENABLE_UNIFIED_MEMBERSHIP ? info.push('Vital Care Premier') : info.push('Vital Care');
  }
  return info;
};

export const getpetCostsByMembershipId = (taxAndDiscountInfoData) => {
  const flag = {};
  const distinctPetCosts = [];
  taxAndDiscountInfoData.forEach(ele => {
    if (ele.membershipId !== undefined && !flag[ele.membershipId]) {
      flag[ele.membershipId] = true;
      distinctPetCosts.push({
        membershipId: ele.membershipId,
        petcosts: ele.petCosts,
        taxRate: ele.taxRate,
        priceAmount: ele.priceAmount,
      });
    }
  });
  return distinctPetCosts;
};

export const getNextStep = (toggles, currentStep) => {
  let action = null;

  if (currentStep === VIEW_INDEX.PET_SELECTION) {
    action = navigationHistory.actions.goToCompleteYourPurchase;
  }

  return action;
};

export const getUsStates = () => [
  { id: 1, label: 'Alabama', postalAbbreviation: 'AL' },
  { id: 2, label: 'Alaska', postalAbbreviation: 'AK' },
  { id: 3, label: 'Arizona', postalAbbreviation: 'AZ' },
  { id: 4, label: 'Arkansas', postalAbbreviation: 'AR' },
  { id: 5, label: 'Armed Forces Americas', postalAbbreviation: 'AA' },
  { id: 6, label: 'Armed Forces Pacific', postalAbbreviation: 'AP' },
  { id: 7, label: 'Armed Forces Europe', postalAbbreviation: 'AE' },
  { id: 8, label: 'California', postalAbbreviation: 'CA' },
  { id: 9, label: 'Colorado', postalAbbreviation: 'CO' },
  { id: 10, label: 'Connecticut', postalAbbreviation: 'CT' },
  { id: 11, label: 'Delaware', postalAbbreviation: 'DE' },
  { id: 12, label: 'District Of Columbia', postalAbbreviation: 'DC' },
  { id: 13, label: 'Florida', postalAbbreviation: 'FL' },
  { id: 14, label: 'Georgia', postalAbbreviation: 'GA' },
  { id: 15, label: 'Guam', postalAbbreviation: 'GU' },
  { id: 16, label: 'Hawaii', postalAbbreviation: 'HI' },
  { id: 17, label: 'Idaho', postalAbbreviation: 'ID' },
  { id: 18, label: 'Illinois', postalAbbreviation: 'IL' },
  { id: 19, label: 'Indiana', postalAbbreviation: 'IN' },
  { id: 20, label: 'Iowa', postalAbbreviation: 'IA' },
  { id: 21, label: 'Kansas', postalAbbreviation: 'KS' },
  { id: 22, label: 'Kentucky', postalAbbreviation: 'KY' },
  { id: 23, label: 'Louisiana', postalAbbreviation: 'LA' },
  { id: 24, label: 'Maine', postalAbbreviation: 'ME' },
  { id: 25, label: 'Maryland', postalAbbreviation: 'MD' },
  { id: 26, label: 'Massachusetts', postalAbbreviation: 'MA' },
  { id: 27, label: 'Michigan', postalAbbreviation: 'MI' },
  { id: 28, label: 'Minnesota', postalAbbreviation: 'MN' },
  { id: 29, label: 'Mississippi', postalAbbreviation: 'MS' },
  { id: 30, label: 'Missouri', postalAbbreviation: 'MO' },
  { id: 31, label: 'Montana', postalAbbreviation: 'MT' },
  { id: 32, label: 'Nebraska', postalAbbreviation: 'NE' },
  { id: 33, label: 'Nevada', postalAbbreviation: 'NV' },
  { id: 34, label: 'New Hampshire', postalAbbreviation: 'NH' },
  { id: 35, label: 'New Jersey', postalAbbreviation: 'NJ' },
  { id: 36, label: 'New Mexico', postalAbbreviation: 'NM' },
  { id: 37, label: 'New York', postalAbbreviation: 'NY' },
  { id: 38, label: 'North Carolina', postalAbbreviation: 'NC' },
  { id: 39, label: 'North Dakota', postalAbbreviation: 'ND' },
  { id: 40, label: 'Ohio', postalAbbreviation: 'OH' },
  { id: 41, label: 'Oklahoma', postalAbbreviation: 'OK' },
  { id: 42, label: 'Oregon', postalAbbreviation: 'OR' },
  { id: 43, label: 'Palau', postalAbbreviation: 'PW' },
  { id: 44, label: 'Pennsylvania', postalAbbreviation: 'PA' },
  { id: 45, label: 'Puerto Rico', postalAbbreviation: 'PR' },
  { id: 46, label: 'Rhode Island', postalAbbreviation: 'RI' },
  { id: 47, label: 'South Carolina', postalAbbreviation: 'SC' },
  { id: 48, label: 'South Dakota', postalAbbreviation: 'SD' },
  { id: 49, label: 'Tennessee', postalAbbreviation: 'TN' },
  { id: 50, label: 'Texas', postalAbbreviation: 'TX' },
  { id: 51, label: 'U.S. Virgin Islands', postalAbbreviation: 'VI' },
  { id: 52, label: 'Utah', postalAbbreviation: 'UT' },
  { id: 53, label: 'Vermont', postalAbbreviation: 'VT' },
  { id: 54, label: 'Virginia', postalAbbreviation: 'VA' },
  { id: 55, label: 'Washington', postalAbbreviation: 'WA' },
  { id: 56, label: 'West Virginia', postalAbbreviation: 'WV' },
  { id: 57, label: 'Wisconsin', postalAbbreviation: 'WI' },
  { id: 58, label: 'Wyoming', postalAbbreviation: 'WY' },
];

export const getUsageDataByCategory = (membershipPlan, planBenefitUsageHistory) => {
  const { membershipBenefits } = membershipPlan;
  const { benefitUsages } = planBenefitUsageHistory;

  const usagesWithBenefitData = getAllUsagesWithBenefitData(membershipBenefits, benefitUsages);

  const uniqueCategoriesData = getUniqueCategoriesData(membershipBenefits);

  const groupedUsagesByCategory = groupUsagesByCategory(uniqueCategoriesData, usagesWithBenefitData);

  orderCategoryUsagesByDate(groupedUsagesByCategory);
  orderByCategoryPriority(groupedUsagesByCategory);

  return groupedUsagesByCategory;
};

export const sortDateList = (dateList) => {
  const dateListSorted = dateList
    .slice()
    .sort((date1, date2) => {
      const MOVE_TO_PREVIOUS_POSITION = -1;
      const MOVE_TO_NEXT_POSITION = 1;

      const dateA = new Date(date1);
      const dateB = new Date(date2);

      const dateIsTheLatest = dateA > dateB;
      const dateIsNotTheLatest = dateA < dateB;

      if (dateIsTheLatest) return MOVE_TO_NEXT_POSITION;
      else
      if (dateIsNotTheLatest) return MOVE_TO_PREVIOUS_POSITION;
      else return 0;
    });

  return dateListSorted;
};

export const getTotalBenefitUsageHistory = (petMasterId, membershipPlans, planBenefitUsageHistory) => {
  let total = 0;
  const membershipPlan = membershipPlans.find((plan) => plan.pet === petMasterId);

  const { benefitUsages } = planBenefitUsageHistory;

  membershipPlan.membershipBenefits.forEach((benefit) => {
    benefitUsages.forEach((benefitUsage) => {
      if (benefit.id === benefitUsage.benefitId) {
        total += benefit.value;
      }
    });
  });

  return total;
};

export const deepClone = (object) => JSON.parse(JSON.stringify(object));

export const timeStampData = (data) => {
  data.cacheTime = moment().format();
  return data;
};

export const closestToCurrentView = (key, navHistory) => {
  console.log(`History Debug: ${key}`);
  const { history, current } = navHistory;
  console.log(`History Debug: ${JSON.stringify(history)}`);
  const sortedMatchingViews = history.reduce(
    (acc, view, index) => {
      let returnVal = acc;

      // if first item or view we are looking for add to array of views
      if (index === 0 || key === view.key) {
        returnVal = [
          ...acc,
          {
            ...view,
            index,
            delta: Math.abs(current - index),
          },
        ];
      }

      return returnVal;
    },
    [],
  ).sort(({ delta: delta1 }, { delta: delta2 }) => delta1 - delta2);
  console.log(`History Debug: ${JSON.stringify(sortedMatchingViews)}`);

  const closestView = sortedMatchingViews[0];
  console.log(`History Debug: ${JSON.stringify(closestView)}`);

  return closestView;
};

export const getFormErrorClassName = (
  className,
  name,
  errors,
  touched,
) => className + (errors[name] && touched[name] ? ' error' : '');

export const trackEvent = (analyticsData) => {
  // Console required by Tagging Team (davidjen@petco.com)
  // post event data to Adobe Analytics
  const objectWithUniqueReference = cloneDeep(analyticsData);
  window.adobeDataLayer = window.adobeDataLayer || [];
  adobeDataLayer.push(objectWithUniqueReference);
};

export const handleKeyUp = (event, callback) => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback();
  }
};

export const sendMembershipSignUpAnalyticsData = (analyticsDataObject, {
  formname,
  formstepname,
  formstep,
  subformname,
}) => {
  const analyticsDataObjectWithStepInfo = {
    ...analyticsDataObject,
    formname,
    formstepname,
    formstep,
    subformname,
  };

  trackEvent(analyticsDataObjectWithStepInfo);
};

export const getMembershipDynamicLabelInputCssClass = (standardCss, enhancedCss, truthnessCondition, fieldValue) => {
  // TODO: to finish implementation by Daniel on his PR
  let result = null;

  if (truthnessCondition) {
    result = `${enhancedCss} ${standardCss}`;
  } else {
    result = standardCss;
  }

  if (!fieldValue) {
    result += ' empty-field';
  }

  return result;
};

export const getCssClass = (standardCss, enhancedCss, truthnessCondition, fieldValue) => {
  // for whenever need dynamic classes
  let result = null;

  if (truthnessCondition) {
    result = `${enhancedCss} ${standardCss}`;
  } else {
    result = standardCss;
  }

  if (!fieldValue) {
    result += ' empty-field';
  }

  return result;
};

export const getDatePickerCssClass = (standardCss, enhancedCss, truthnessCondition, fieldValue) => {
  // an exclusive class for the React Date Picker css component classes
  let result = null;

  if (truthnessCondition) {
    result = `${enhancedCss} ${standardCss}`;
  } else {
    result = standardCss;
  }

  if (!fieldValue) {
    result += ' empty-field';
  }

  return result;
};
/*
** Below function is used to set height of <> to make EnrollNow button sticky at the bottom
*/
export const stickyButtonStyle = (height, marginLeft) => {
  const screenHeight = window.outerHeight;
  const style = {
    maxHeight: `${screenHeight - height}px`,
    marginLeft: `${marginLeft}px`,
  };
  return style;
};
export const stickyButtonStyleDiv = (height) => {
  const screenHeight = window.outerHeight;
  const style = {
    minHeight: `${(screenHeight - height) + 70}px`,
    position: 'relative',
  };
  return style;
};
/*
Below const's are used to tell OS of device
*/
export const isAndroidDevice = /android/i.test(navigator.userAgent);
export const isIoSDevice = /iPhone|iPod/.test(navigator.userAgent);
/*
** Below function is used to generate unique id as a reference for Payment in new architectural implementation.
*/
export const getUniqueReferenceId = (petMasterId) => {
  const timeStamp = Date.now().toString().substring(8, 13);
  const randomString = Math.random().toString(36).substring(2, 7);
  const referenceId = petMasterId + randomString + timeStamp;
  const encryptedReferenceId = Buffer.from(referenceId).toString('base64');
  const charsRefId = encryptedReferenceId.split('').slice(0, 16).join('');
  const encryptedRefId = Buffer.from(charsRefId).toString('base64').split('').slice(0, 16)
    .join('');
  return encryptedRefId;
};

export const renderPetIconsForUpdatePlansCC = (petType) => {
  if (petType === 'Dog') {
    return <Icon className="updatePlansCC-icon-dog" name="updatePlansCCDog" />;
  } else if (petType === 'Cat') {
    return <Icon className="updatePlansCC-icon-cat" name="updatePlansCCCat" />;
  } else if (petType === 'Fish') {
    return <Icon className="updatePlansCC-icon-fish" name="updatePlansCCFish" />;
  } else if (petType === 'Bird') {
    return <Icon className="updatePlansCC-icon-bird" name="updatePlansCCBird" />;
  } else if (petType === 'Reptile') {
    return <Icon className="updatePlansCC-icon-reptile" name="updatePlansCCReptile" />;
  } else if (petType === 'Small Animal') {
    return <Icon className="updatePlansCC-icon-small-animal" name="updatePlansCCSmallAnimal" />;
  }
};

export const checkToggles = (apuKey, uiTextApuKey, result) => {
  if (apuKey === uiTextApuKey) {
    return { ...result, ANNUAL_PAID_UPFRONT_ONLINE: true };
  } else {
    return result;
  }
};

export const getRequestHeaders = (apuKey, uiTextApuKey) => {
  if (apuKey === uiTextApuKey) {
    return { 'Content-Type': 'application/json', ApuKey: apuKey };
  } else {
    return { 'Content-Type': 'application/json' };
  }
};

export const getRequestHeaderForThreeKeyPilot = (threeKeyParam, uiTextThreeKey) => {
  if (threeKeyParam === uiTextThreeKey) {
    return { 'Content-Type': 'application/json', ThreeKey: threeKeyParam };
  } else {
    return { 'Content-Type': 'application/json' };
  }
};

export const getCardInfoForAdobeTracking = (array, paymentMethodId) => {
  const filteredArray = array.filter((paymentMethod) => paymentMethod.mbrPayinfoId === paymentMethodId);
  return filteredArray;
};
